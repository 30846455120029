const linkData = [
  {
    id: 1,
    text: "Home",
    url: "/#home",
  },
  {
    id: 2,
    text: "Projects",
    url: "/#projects",
  },
  {
    id: 3,
    text: "About",
    url: "/#about",
  },
  {
    id: 4,
    text: "Contact",
    url: "/#contact",
  },
];

export default linkData;
